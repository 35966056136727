import React from "react";
import { Grid, withStyles, Typography, Card, Avatar } from "@material-ui/core";
// import { localStorageKeys } from '../../utils';
import { withRouter } from "react-router-dom";
import { getAllNotification } from "../../graphql/queries";
import { withApollo } from "react-apollo";
import { Header } from "../../components";
import moment from "moment";
import { RequestData } from "../../funcations"

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  mainContent: {
    height: "100vh",
    background: "#fdeef7",
    overflow: "auto",
    borderRadius: 20,
    width: 400,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: "100%"
    },
  },
  body: {
    padding: 20,
    height: "calc(100vh - 64px)",
    overflow: "auto"
  },
  listOfDes: {
    marginBottom: theme.spacing(2),
    padding: "14px 18px",
    borderRadius: 20,
    cursor: "pointer",
    border: "1.4px solid #fff",
    transition: "0.4s ease"
  },
  mainTitle: {
    padding: "0px 20px 20px 20px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  cardTitle: {
    display: "flex",
    justify: "start",
    alignItems: "center",
    background: "#EEEEEE",
    padding: 4,
    borderRadius: 4,
    marginTop: 6,
    marginBottom: 6,
    borderLeft: "3px solid " + theme.palette.secondary.main
  },
  title: {
    fontSize: 20,
    fontFamily: "humanchain_bold !important"
  },
  notificationCount: {
    width: 18,
    height: 18,
    fontSize: 12,
    background: theme.palette.secondary.main,
    marginLeft: 8,
  }
});



class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = { list: [] };
  }

  componentDidMount() {
    if(!localStorage.userId){
      return false
    }
    this.props.client.query({
      query: getAllNotification,
      variables: { notificationTo: localStorage.userId },
      fetchPolicy: 'no-cache'
    }).then(res => {
      let listData = []
      res.data.allNotifications.nodes.map(val => {
        let data = RequestData(val?.requestByRequestId?.request ?? "")

        listData.push({
          name: data.name + " needs " + val?.requestByRequestId?.masterRequestTypeByRequestType?.requestType ?? "",
          message: val.message,
          // contact_number: "you can reach him at 9003184751.",
          updatedAt: new Date(val.createdAt),
          requestId: val?.requestId
        })
        return false
      })
      this.setState({ list: listData })
    })
  }

  handleScroll = (e) => {
    // const target = e.target;
    // if (target.scrollHeight - target.scrollTop === target.clientHeight) {
    //   // alert("end")
    //   console.log("end")
    // }
  }

  onNotificationClicked = (val) =>{
    this.props.history.push('/_' + val.requestId)
  }

  render() {
    const { classes } = this.props;
    const { list } = this.state;
    return (
      <div className={classes.root}>
        <Grid container justify={"center"}>
          <Grid item className={classes.mainContent}>
            {/* header */}
            <Header showCloseIcon />
            <Grid className={classes.body} onScroll={this.handleScroll} >
              <Grid item className={classes.mainTitle}>
                <Typography variant={"h5"} style={{ fontSize: 16, fontWeight: 600 }} >
                  Notifications
                </Typography>
                <Avatar className={classes.notificationCount}>
                  {list.length}
                </Avatar>
              </Grid>
              {/* Notification card list */}
              {list.map((val, i) => {
                return (
                  <Card className={classes.listOfDes} elevation={4} key={i} onClick={()=>this.onNotificationClicked(val)}>
                    <div>
                      <Typography variant={"subtitle2"} color={"textSecondary"} style={{ fontSize: "13px" }} >{(val.message ? val.message : "") + " " + (val.contact_number ? val.contact_number : "")}</Typography>
                    </div>
                    <Grid item className={classes.cardTitle}>
                      <Typography variant={"body1"} color={"textPrimary"} style={{ fontSize: 14, marginLeft: 10 }} >{val.name}</Typography>
                    </Grid>
                    <Typography variant={"subtitle2"} color={"textSecondary"} style={{ fontSize: "10px" }} >{moment(val.updatedAt).format("LT, Do MMM 'YY")}</Typography>
                  </Card>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(withApollo(Notification)));