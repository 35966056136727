import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  DialogContentText,
  Button
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
const useStyles = makeStyles(theme => ({
  root: {},
  dialog: {
    borderRadius: 10,
    '& .MuiDialogActions-root': {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        '& .MuiButtonBase-root': {
          width: '80%',
          '&:not(:first-child)': {
            marginTop: '10px'
          }
        }
      }
    }
  },
  title: {
    textAlign: 'center'
  },
  dialogActions: {
    padding: '0px 24px 16px 24px'
  },
  close: {
    borderRadius: 20,
    border: `1.1px solid #bdbdbd2e`,
    padding: 6,
    marginLeft: 10,
    cursor: 'pointer',
    marginTop:-40,
    [theme.breakpoints.up('md')]: {
      marginTop:-30,
    }
  },
  titlediv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom:16
  },
  text: {
    textTransform: 'capitalize',
    cursor: 'pointer',
    height:"40px",
    [theme.breakpoints.down('xs')]: {
      height:"50px",
    }
  }
}))

export const DialogComponent = props => {
  const classes = useStyles()

  const [state, setState] = React.useState({
    open: props?.open ?? false,
    title: props?.title ?? '',
    body: props?.body ?? '',
    positiveBtn: props?.positiveBtn ?? 'Ok',
    negativeBtn: props?.negativeBtn ?? 'Cancel',
    negativeBtnDontShow: props?.negativeBtnDontShow ?? false,
    positiveBtnDontShow: props?.positiveBtnDontShow ?? false,
    onOk: () => props?.onOk ?? null,
    onCancel: props?.onCancel ?? null
  })
  React.useEffect(() => {
    setState({
      open: props?.open ?? false,
      title: props?.title ?? '',
      body: props?.body ?? '',
      positiveBtn: props?.positiveBtn ?? 'Ok',
      negativeBtn: props?.negativeBtn ?? 'Cancel',
      negativeBtnDontShow: props?.negativeBtnDontShow ?? false,
      positiveBtnDontShow: props?.positiveBtnDontShow ?? false,
      onOk: () => props?.onOk ?? null,
      onCancel: props?.onCancel ?? null
    })
  }, [props])
  return (
    <div className={classes.root}>
      <Dialog
        open={state.open}
        onClose={state.onCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        classes={{
          paper: classes.dialog
        }}
      >
        <DialogTitle id='alert-dialog-title' className={classes.title}>
          <div className={classes.titlediv}>
            {state.title}
            <CloseIcon className={classes.close} onClick={props.onCancel} />
          </div>
        </DialogTitle>
        {state.body && (
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {state.body}
            </DialogContentText>
          </DialogContent>
        )}
        {(!Boolean(state?.negativeBtnDontShow) ||
          !Boolean(state?.positiveBtnDontShow)) && (
          <DialogActions className={classes.dialogActions}>
            {!Boolean(state?.negativeBtnDontShow) && (
              <Button
                onClick={props.onCancel}
                color='primary'
                variant='outlined'
                className={classes.text}
                style={{
                  marginLeft:6
                }}
              >
                {state.negativeBtn}
              </Button>
            )}
            {!Boolean(state?.positiveBtnDontShow) && (
              <Button
                onClick={props.onOk}
                color='primary'
                variant='contained'
                className={classes.text}
              >
                {state.positiveBtn}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}
