import React from 'react';
import { FormRenderer } from './formRenderer';
import { withRouter } from 'react-router-dom';
import { LoadingSection, InternatlServerError } from '../../components';
import { Message, NetWorkCallMethods } from '../../utils';
import { Routes } from '../../router/routes';
import { NetworkCall } from "../../networkcall";
import config from '../../config';
class FormRendererParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form_detail: {
                data: {
                    form: [],
                    form_detail: [],
                    tree: [],
                    bulkAnswers: {}
                },
                loading: true,
                error: false
            }
        }
    }

    componentDidMount() {
        this.getFormDetail();
    }

    getFormDetail = () => {
        let id = this.props.match.params.id;
        let entryId = this.props.match.params.entry_id;

        let params = {
            form_id: parseInt(id),
            entryId,
        };

        NetworkCall(
            config.api_url + "form/get",
            NetWorkCallMethods.post,
            params
        ).then((res) => {
            if (res?.data?.data && res?.data?.type === 'Success') {
                let form_detail = this.state.form_detail;
                form_detail.error = false;
                form_detail.loading = false;
                form_detail.data.form = res.data?.data?.forms;
                form_detail.data.form_detail = res.data?.data;
                this.setState({
                    form_detail
                })
            }
        }).catch(error => {
            let form_detail = this.state.form_detail;
            form_detail.error = true;
            form_detail.loading = false;
            this.setState({
                form_detail
            })
        })
    };

    giveMeAnswer = (bulkAnswers) => {
        let answer = {};

        bulkAnswers?.answers?.map((ans, index) => {
            return answer[ans?.question_id] = ans.answer;
        })

        return answer;
    }

    render() {

        const {
            data,
            loading,
            error
        } = this.state?.form_detail;

        const {
            form = [],
            form_detail = [],
            tree = [],
        } = data;

        const formId = this.props.match.params.id;
        const entryId = this.props.match.params.entry_id;
        const isReadyOnly = this.props.match.path === Routes.view_form;

        return <div>
            {loading && !error && <LoadingSection message={Message.loading} />}

            {error && <InternatlServerError onRetry={this.getFormDetail} />}

            {!loading && !error && <FormRenderer
                form_detail={form_detail}
                tree_view={tree}
                forms={form}
                parent_form_id={formId}
                entry_id={entryId}
                isEdit={entryId ? true : false}
                isReadyOnly={isReadyOnly}
                answer={this.props?.answer ?? {}}
                onSubmitBtnClicked={this.props.onSubmitBtnClicked}
                submitBtnName={this.props.submitBtnName}
            />}
        </div>
    }
}

export default (withRouter(FormRendererParent));