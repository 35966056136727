import gql from 'graphql-tag';

const createCommend = gql`mutation(
    $id: UUID!
    $comment: String
    $createdBy: UUID
    $createdAt: Datetime
    $requestId: UUID
    $updatedAt: Datetime
    $updatedBy: UUID
  ) {
    createComment(
      input: {
        comment: {
          id: $id
          comment: $comment
          createdBy: $createdBy
          createdAt: $createdAt
          requestId: $requestId
          updatedAt: $updatedAt
          updatedBy: $updatedBy
          isActive: true
        }
      }
    ) {
      comment {
        id
      }
    }
  }
  `

const closeMyRequest = gql`mutation($id: UUID!, $closeReason: Int, $specialNote:String) {
  updateRequestById(
    input: { id: $id, requestPatch: { closeReason: $closeReason, specialNote : $specialNote, status: 2 } }
  ) {
    request {
      id
    }
  }
}
`


export {
  createCommend, closeMyRequest
};