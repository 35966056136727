import React from 'react'
import { withRouter } from 'react-router-dom'
import {
    Grid,
    withStyles,
} from "@material-ui/core";
import { NetworkCall } from "../../networkcall";
import { Header, InternatlServerError, LoadingSection, NotFoundSection } from "../../components";
import CONFIG from '../../config';
import { GiveMeShareURL, NetWorkCallMethods } from "../../utils";
import { Feedcard } from "../../components";
import { RequestData } from "../../funcations";
import { withApollo } from "react-apollo";
import { getRequestDetails } from "../../graphql/queries";
import { Helmet } from "react-helmet";

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    mainContent: {
        height: "100vh",
        background: "#fdeef7",
        overflow: "auto",
        borderRadius: 14,
        width: 400,
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0
        },
        [theme.breakpoints.down('xs')]: {
            borderRadius: 0,
            width: "100%"
        },
    },
    body: {
        padding: 20,
        height: "calc(100vh - 64px)",
        overflow: "auto"
    }
});
class ShareRequestDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: null,
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getRequestDetail();
    }

    getRequestDetail = () => {

        let request_id = this.props.match?.params?.id?.split("_")?.[1] ?? "";

        let body = { request_id: request_id };

        NetworkCall(
            CONFIG.api_url + "/request/viewCount/update",
            NetWorkCallMethods.post,
            body
        )

        this.setState({
            loading: true,
            error: false
        })

        this.props.client.query({
            query: getRequestDetails,
            variables: {
                reqId: request_id
            },
            fetchPolicy: 'no-cache'
        }).then(res => {
            let listData = []
            res.data.allRequests.nodes.map(val => {
                let json = RequestData(val.request)
                let link = GiveMeShareURL(json, val?.masterRequestTypeByRequestType?.requestType, request_id)
                listData.push({
                    date: new Date(val.createdAt).toDateString(),
                    title: json.name + ' needs ' + val?.masterRequestTypeByRequestType?.requestType,
                    nameList: [
                        json.mention,
                        json.age + ', ' + json.gender,
                        json.mno,
                        json.address
                    ],
                    isemergency: json.ems,
                    shareUrl: val.requestType,
                    link: link,
                    ...val
                })
                return false
            })
            this.setState({
                list: listData[0],
                loading: false,
                error: false
            }, () => {
                this.props.history.push(`/${this.state.list.link}`)
            })
        }).catch(error => {
            console.log('error:', error)
            this.setState({
                list: {},
                loading: false,
                error: true
            })
        })
    }

    giveMeTitle = (list) => {
        let title = "Human Chain Request | ";
        title += list?.title ?? "";
        let arr = ['', ' | Open ', ' | Close', ' | Expired'];
        return title + arr[list?.status ?? 0];
    }

    render() {
        const { classes } = this.props
        const { list, loading, error } = this.state
        return (
            <div className={classes.root}>
                <Helmet encodeSpecialCharacters={true}
                    onChangeClientState={(newState, addedTags, removedTags) => console.log(newState, addedTags, removedTags)} >
                    <html lang="en" />
                    <title>{this.giveMeTitle(list)}</title>
                    <meta name="description" content={`
                        Emergency: ${list?.isemergency ? "Yes" : "No"} |
                        Age & Gender: ${list?.nameList?.[1] ?? "-"}
                        Mobile No: ${list?.nameList?.[2] ?? "-"}
                        Locality: ${list?.nameList?.[3] ?? "-"}
                        Needs: ${list?.nameList?.[0] ?? "-"}
                        `} />
                    <link rel="canonical" href={window.location.href} />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={"Human Chain"} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content={this.giveMeTitle(list)} />
                    <meta property="og:image" content="%PUBLIC_URL%/icons/192x192.png" />

                </Helmet>
                <Grid container justify={"center"}>
                    <Grid item className={classes.mainContent}>
                        <Header
                            isNoHelmat={true}
                            showAvatar={false}
                            showNotificationIcon={false}
                            discription={true}
                        />
                        <Grid className={classes.body}>

                            {loading && !error && <LoadingSection message="Fetching Request Details..." />}

                            {!loading && error && <InternatlServerError message="Something went wrong, Unable to get the request detail" onRetry={this.getRequestDetail} />}

                            {!loading && !error && !list && <NotFoundSection message="404 - Request not found" />}

                            {!loading && !error && list && <Feedcard
                                list={list}
                                dontredirect
                                dontShowShare={true}
                                handleShare={() => this.handleShare()}
                                handleopemodle={this.handleopemodle}
                                history={this.props.history}
                            />}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(withApollo(ShareRequestDetails)))
