import React from 'react'
import { withRouter } from 'react-router-dom'
import { Routes } from '../../router/routes'
import {
  Typography,
  Grid,
  withStyles,
  Card,
  CardHeader,
  TextField
} from '@material-ui/core'
import { Header } from '../../components'
import { RequestData } from '../../funcations'
import { Feedcard } from '../../components'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { withApollo } from 'react-apollo'
import { getAllRequest, getAllMasterCloseReason } from '../../graphql/queries'
import { closeMyRequest } from '../../graphql/mutations'
import { DialogComponent } from '../../components/dialog/index'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 20,
    width: 400,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  subtitle: {
    marginBottom: '-16px',
    fontWeight: 600,
    marginTop: 22
  },
  card: {
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,.01)',
    margin: '28px 0px',
    borderRadius: 20
  },
  closed: {
    background: '#EEEEEE52',
    boxShadow: 'none',
    padding: 10,
    margin: '10px 0px',
    border: '1px solid #eaeaea',
    '& .title': {
      fontSize: 15,
      fontWeight: 600,
      color: '#9B9B9B'
    },
    '& .dis': {
      fontSize: 15
    },
    '& .reopen': {
      fontSize: 16
    }
  },
  body: {
    padding: 20,
    height: 'calc(100vh - 64px)',
    overflow: 'auto'
  },
  cardDescription: {
    minHeight: 100,
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    padding: 20,
    color: '#fff',
    borderRadius: 20,
    fontSize: 20,
    paddingTop: 24,
    cursor: 'pointer'
  },
  closecardDescription: {
    cursor: 'pointer',
    marginBottom: 8,
    background: `${theme.palette.primary.main}`,
    padding: 0,
    color: theme.palette.primary.contrastText,
    borderRadius: 6,
    '& .title': {
      fontSize: 14
    },
    '& .head': {
      padding: '8px 10px',
      marginTop: -6
    }
  },
  subtitles: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.9,
    fontSize: 13,
    '& .icon': {
      fontSize: 11,
      marginLeft: 6
    }
  },
  nodata: {
    marginTop: 15,
    textAlign: 'center'
  },
  backimage1: {
    backgroundImage: `url(${'"/images/Mask Group 5.png"'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }
})
class Requests extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      list: [],
      closedata: [],
      closeReason: null,
      whywontclose: false,
      sharenote: false,
      close: false,
      selectedReqId: null
    }
  }

  componentDidMount () {
    if (!localStorage.userId) {
      return false
    }

    this.props.client
      .query({
        query: getAllMasterCloseReason,
        variables: {},
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.setState({
          closedata: res.data.allMasterRequestCloseReasons.nodes
        })
      })

    this.fetchAllData()
  }

  fetchAllData = () => {
    this.props.client
      .query({
        query: getAllRequest,
        variables: {
          userId: localStorage.userId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        let listData = []

        res.data.allRequests.nodes.map((val, i) => {
          let json = RequestData(val.request)
          let mentionarr = [
            { name: json.mention, img: '/images/patient.svg' },
            {
              name: json.age + ', ' + json.gender,
              img: '/images/calendar (1).svg'
            },
            { name: json.mno, img: '/images/cell-phone.svg' },
            { name: json.address, img: '/images/location.svg' }
          ]
          mentionarr = mentionarr.filter(val => val.name && val.name.length > 0)
          listData.push({
            date: new Date(val.createdAt).toDateString(),
            title:
              json.name +
              ' needs ' +
              val?.masterRequestTypeByRequestType?.requestType,
            nameList: mentionarr,
            isemergency: json.ems,
            shareUrl: val.requestType,
            ...val
          })
          return val
        })

        this.setState({
          list: listData
        })
      })
      .catch(e => {
        debugger
        alert('something went wrong!')
      })
  }

  handleShare = id => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Share Post',
          text: `Check out our latest request`,
          url: Routes.sharedRequestuse + id
        })
        .then(() => {
          console.log('Successfully shared')
        })
        .catch(error => {
          console.error('Something went wrong sharing the blog', error)
        })
    }
  }

  handlechoosewhyclose = _list => {
    const state = this.state
    this.setState({
      ...state,
      closeReason: _list,
      sharenote: !state.sharenote,
      whywontclose: false
    })
  }

  dialogComponentBody = classes => {
    const state = this.state
    if (state.whywontclose) {
      return (
        <div>
          {state.closedata?.map(_list => {
            return (
              <Card
                className={classes.closecardDescription}
                elevation={4}
                onClick={() => this.handlechoosewhyclose(_list)}
              >
                <CardHeader
                  className='head'
                  title={<span className='title'>{_list?.name}</span>}
                />
              </Card>
            )
          })}
        </div>
      )
    } else if (state.sharenote) {
      return (
        <Card
          className={classes.closecardDescription}
          elevation={4}
          // onClick={() => this.handlechoosewhyclose()}
        >
          <TextField
            type='text'
            fullWidth
            placeholder='Type here...'
            onChange={e => this.setState({ specialNote: e.target.value })}
          />
        </Card>
      )
    }
  }

  handleopemodle = reqId => {
    const state = this.state
    this.setState({
      selectedReqId: reqId,
      whywontclose: !state.whywontclose
    })
  }

  handleonCancel = () => {
    const state = this.state
    this.setState({
      ...state,
      whywontclose: false,
      sharenote: false,
      close: false
    })
  }

  handleoky = async () => {
    const state = this.state
    if (!state.whywontclose && state.sharenote) {
      this.setState({
        ...state,
        whywontclose: false,
        sharenote: false,
        close: true
      })
    } else {
      await this.props.client
        .mutate({
          mutation: closeMyRequest,
          variables: {
            id: state.selectedReqId,
            closeReason: state?.closeReason?.id,
            updatedBy: localStorage.userId,
            specialNote: state?.specialNote
          }
        })
        .then(data => {
          this.setState(
            {
              ...state,
              whywontclose: false,
              sharenote: false,
              close: false
            },
            () => {
              this.fetchAllData()
            }
          )
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  render () {
    const { classes } = this.props
    const { list, whywontclose, sharenote, close } = this.state
    return (
      <div className={classes.root}>
        <DialogComponent
          negativeBtnDontShow={whywontclose ? true : false}
          positiveBtnDontShow={whywontclose ? true : false}
          title={
            (whywontclose && 'Why do you want to close?') ||
            (sharenote && 'Share your thank you note (Optional)') ||
            (close && 'Are you sur, would you like to colse the request?')
          }
          open={whywontclose || sharenote || close}
          body={this.dialogComponentBody(classes)}
          onOk={this.handleoky}
          onCancel={this.handleonCancel}
          positiveBtn='Close Request'
          negativeBtn='Cancel'
        />
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            <Header
              showAvatar={true}
              showNotificationIcon={true}
              discription={true}
            />
            <Grid className={classes.body}>
              <Card
                onClick={() => this.props.history.push(Routes.registrationType)}
                className={classes.cardDescription + ' ' + classes.backimage1}
                elevation={4}
              >
                Do you need help?
                <span className={classes.subtitles}>
                  Post and share request
                  <ArrowForwardIosIcon fontSize='small' className='icon' />
                </span>
              </Card>

              <Typography variant='h6' className={classes.subtitle}>
                My Requests
              </Typography>
              {list.length === 0 && (
                <Typography className={classes.nodata}>
                  No Data Found
                </Typography>
              )}
              <div
                style={{
                  marginBottom: '80px'
                }}
              >
                {list?.map(_list => {
                  return (
                    <Feedcard
                      list={_list}
                      handleShare={this.handleShare}
                      handleopemodle={this.handleopemodle}
                      history={this.props.history}
                    />
                  )
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(withApollo(Requests)))
