import React from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Routes } from "../../router/routes";
import { withAllContexts } from '../../HOCs';
import { Header } from '../../components';
import { FormRenderer } from "..";
import CONFIG from '../../config';
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { getLocal, setLocal } from "../../funcations/common";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  body: {
    padding: 20,
    height: "calc(100vh - 64px)",
    overflow: "auto",
  },
  mainContent: {
    height: "100vh",
    background: "#fdeef7",
    overflow: "auto",
    borderRadius: 20,
    width: 400,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: "100%"
    },
  },
  title: {
    marginBottom: theme.spacing(3),
  },
});

class RequestDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  onSubmitBtnClicked = async (state) => {

    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: 'Sending OTP to your contact number...'
    })

    state.mobileNo = this.giveMeMobileNumber(state);
    state.form_id = this.props.match.params.id;

    if (state.mobileNo.length < 10) {

      this.props.alert.setSnack({
        ...this.props.alert.alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Please Enter Valid Contact Number!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center
      })

      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false
      })

      return false
    }

    let body = { "mobile": state.mobileNo };

    NetworkCall(
      CONFIG.api_url + "/notification/otp/send",
      NetWorkCallMethods.post,
      body
    ).then(res => {
      this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: false });
      this.props.history.push({
        pathname: Routes.OtpVerifyResponse,
        state:{
          ...state,
          ...this.props?.location?.state,
        }
      })
      setLocal('response_data', state?.answer);
    }).catch(error => {
      this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: false });
      this.props.alert.setSnack({
        ...this.props.alert.alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something went wrong, Unable to send OTP",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center
      })
    });
  }

  giveMeMobileNumber = (state) => {
    let mobileNo = '';

    state?.selected_form?.framed_questions?.map(qn => {
      if (qn.ui_component === 'input_number' || qn.tag === 'Mobile Number') {
        mobileNo = state?.answer?.[qn.id]?.answer
      }
      return qn;
    })

    return mobileNo;
  }


  render() {

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container justify={"center"}>
          <Grid item xs={12} sm={5} md={4} lg={3} className={classes.mainContent}>
            <Header showCloseIcon />
            <Grid className={classes.body}>
              <Typography
                variant="h6"
                className={classes.title}
              >
                {"Hope you got in touch with the contact to mark it as verified."}
              </Typography>

              <FormRenderer
                onSubmitBtnClicked={this.onSubmitBtnClicked}
                submitBtnName={'Share'}
                answer={getLocal('response_data')}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(withAllContexts(RequestDetailsComponent)));
