import { makeStyles, Grid, Button } from '@material-ui/core'
import React from 'react'
import { ComponentToRender } from './componentToRender'
import { AlertContext } from '../../contexts'
import { AlertProps } from '../../utils'
import { DialogComponent } from '../../components/dialog/index'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  formTitle: {
    fontWeight: 'bold'
  },
  header: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 2
  },
  uiComponentWrapper: {
    marginTop: 10,
    padding: '8px 0px'
  },
  savebtn: {
    padding: '6px 12px',
    borderRadius: 6
  },
  draftbtn: {
    padding: '6px 12px',
    borderRadius: 6,
    marginLeft: '5px',
    marginRight: '5px'
  },
  nxtBtn: {
    fontSize: '0.8125rem',
    display: 'flex'
  },
  btn: {
    height: '50px',
    borderRadius: 10,
    marginBottom: '80px'
  }
}))

export const FormBuilder = ({
  form_id,
  forms,
  answer = {},
  isReadyOnly = false,
  onAnswerUpdated = () => false,
  setView,
  onSubmitBtnClicked,
  submitBtnName
}) => {
  const classes = useStyles()

  const alert = React.useContext(AlertContext)

  const [smartAnswer, setSmartAnswer] = React.useState(true)
  const [open, setopen] = React.useState(false)
  const handleopen = () => {
    setopen(!open)
  }
  const [formsWithAnswer, setFormsWithAnswer] = React.useState(answer)

  let currentForm = forms[0]
  let currentIndex = 1

  if (form_id) {
    for (let index = 0; index < forms.length; index++) {
      const fr = forms[index]
      if (fr.id === form_id) {
        currentForm = fr
        currentIndex = index + 1
        break
      }
    }
  }

  const onChange = (index, data, value) => {
    let newForms = formsWithAnswer

    newForms[data?.id] = {
      id: data?.id,
      tag: data?.tag,
      answer: value
    }

    setFormsWithAnswer({
      ...newForms
    })
    onAnswerUpdated(newForms)
  }

  React.useEffect(() => {
    if (currentForm?.framed_questions?.[0]?.priority === 0) {
      let answer =
        formsWithAnswer?.[currentForm?.framed_questions?.[0]?.id]?.answer ??
        'No'
      setSmartAnswer(answer === 'Yes')
    } else {
      setSmartAnswer(false)
    }

    setView([currentIndex, currentForm])
    // eslint-disable-next-line
  }, [currentForm, formsWithAnswer])

  const canIshowSmartQuestion = currentForm => {
    return currentForm?.framed_questions?.[0]?.priority === 0
  }

  const isSmartAnswerCheckNeed = currentForm => {
    if (currentForm?.framed_questions?.[0]?.priority === 0) {
      return smartAnswer
    } else {
      return true
    }
  }

  const canIVisibleHere = question => {
    if (question?.dependent) {
      let valueArr = question?.dependent?.option?.map(_ => _.value) ?? []

      let ansIndex =
        formsWithAnswer[question?.dependent?.parent_question_id]?.answer
          ?.value ??
        formsWithAnswer[question?.dependent?.parent_question_id]?.answer

      if (valueArr.indexOf(ansIndex) > -1) {
        return true
      }

      return false
    }
    return true
  }

  const giveMeSM = ui_component => {
    if (ui_component === 'custom_table' || ui_component === 'custom_table_2') {
      return 12
    } else {
      return 12
    }
  }

  const checkRequired = (form, answer) => {
    const requiredTrueids = []
    const answerdetails = answer
    const availabletrues = []

    if (requiredTrueids.length > 0 && Object.keys(answer).length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: 'Please Fill the Required Fields !',
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center
      })
      return false
    }

    let isSmartSkip = ''

    const FramedQuestions = form.framed_questions

    const checkSmartQuesionAvailable = FramedQuestions.map(item => {
      if (item.is_required === true && item.ui_component === 'popup_switch') {
        return true
      } else {
        return false
      }
    })

    // eslint-disable-next-line
    const mapFramedQuestion = FramedQuestions.map(item => {
      if (item.is_required === true && item.ui_component !== 'label') {
        requiredTrueids.push(item)
      }
    })

    const requiredId = requiredTrueids.map(item => {
      return item.id
    })
    // eslint-disable-next-line
    const checking = requiredId.map(item => {
      if (answerdetails[item]) {
        //
        if (
          answerdetails[item]?.answer?.length > 0 ||
          answerdetails[item]?.answer?.value?.length > 0 ||
          answerdetails[item]?.answer?.tableData?.length > 0 ||
          answerdetails[item]?.answer?.table_scheme?.row?.length > 0 ||
          answerdetails[item]?.answer?.table_scheme?.column?.length > 0 ||
          answerdetails[item]?.answer?.length > 0 ||
          answerdetails[item]?.answer?.address?.length > 0
        ) {
          availabletrues.push(answerdetails[item])
        }

        // validation for smart skip
        if (
          checkSmartQuesionAvailable &&
          answerdetails[item]?.answer === 'No'
        ) {
          let requiredTrueidsLength = requiredTrueids.length
          availabletrues.length = requiredTrueidsLength
          isSmartSkip = true
        }
      }
    })

    //
    if (checkSmartQuesionAvailable && isSmartSkip) {
      return true
    } else {
      if (requiredId.length === availabletrues.length) {
        // console.log("verified");
        return true
      } else if (requiredId.length !== availabletrues.length) {
        // console.log("please check")
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.warning,
          msg: 'Please Fill the Required Fields !',
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center
        })

        return false
      }
    }
  }

  const giveMeMD = ui_component => {
    if (ui_component === 'custom_table' || ui_component === 'custom_table_2') {
      return 12
    } else {
      return 12
    }
  }

  const onSubmit = () => {
    setopen(!open)
    let validated = isReadyOnly
      ? true
      : checkRequired(currentForm, formsWithAnswer)
    if (validated) {
      onSubmitBtnClicked(validated)
    }
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction='column'
        justify='flex-start'
        alignItems='stretch'
      >
        {canIshowSmartQuestion(currentForm) && (
          <Grid item xs={12} className={classes.uiComponentWrapper}>
            <ComponentToRender
              data={currentForm?.framed_questions?.[0]}
              value={
                formsWithAnswer?.[currentForm?.framed_questions?.[0]?.id]
                  ?.answer ?? ''
              }
              index={0}
              onChange={onChange}
            />
          </Grid>
        )}

        {/* <Divider variant='fullWidth' /> */}
        <Grid container>
          {isSmartAnswerCheckNeed(currentForm) &&
            currentForm?.framed_questions &&
            currentForm?.framed_questions
              ?.filter(frQ => frQ?.priority !== 0)
              .map((frQ, i) => {
                if (canIVisibleHere(frQ)) {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={giveMeSM(frQ?.ui_component)}
                      md={giveMeMD(frQ?.ui_component)}
                      key={i}
                      className={classes.uiComponentWrapper}
                    >
                      <ComponentToRender
                        data={frQ}
                        isrequired={frQ.is_required}
                        value={formsWithAnswer?.[frQ.id]?.answer ?? ''}
                        index={i}
                        onChange={onChange}
                        isReadyOnly={
                          isReadyOnly && !currentForm.is_investigator_section
                            ? true
                            : false
                        }
                      />
                    </Grid>
                  )
                } else {
                  return <></>
                }
              })}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <DialogComponent
            title={'Are you sur, would you like to colse the request?'}
            open={open}
            onOk={onSubmit}
            onCancel={handleopen}
            positiveBtn='Yes'
            negativeBtn='No'
          />
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={handleopen}
            className={classes.btn}
          >
            {submitBtnName}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
