import React from 'react'
import { withRouter } from 'react-router-dom'
import { Typography, Grid, withStyles, Button, Card, TextField } from '@material-ui/core'
import { Header } from '../../components'
import { NetworkCall } from "../../networkcall";
import Config from '../../config'

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    mainContent: {
        height: '100vh',
        background: '#fdeef7',
        borderRadius: 20,
    },
    otp: {
        margin: 'auto',
        '& input': {
            height: '4.6em',
            width: '4.6em !important',
            background: '#EEEEEEA6',
            borderRadius: 10,
            border: 'none',
            '&:focus-visible': {
                border: '2px solid #ff00a8',
                outline: 'none'
            }
        }
    },
    subtitle: {
        fontSize: 21,
        textAlign: 'left',
        fontWeight: 600
    },
    confirambtn: {
        width: '100%',
        padding: '14px',
        margin: '10px 0px',
        marginTop: 16,
        marginBottom: 30,
        '&:hover': {
            background: theme.palette.primary.main
        }
    },
    change: {
        cursor: 'pointer',
        fontSize: 15,
        textTransform: 'capitalize',
        borderRadius: 8,
        padding: '4px 22px'
    },
    enterotp: {
        fontSize: 16,
        marginBottom: 20
    },
    body: {
        padding: 20,
        height: 'calc(100vh - 64px)',
        overflow: 'auto'
    },
    otptitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    cardDescription: {
        minHeight: 100,
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.secondary.contrastText,
        padding: 20,
        color: '#fff',
        borderRadius: 20,
        fontSize: 20,
        paddingTop: 24
    },
    recived: {
        justifyContent: 'center',
        display: 'flex',
        fontSize: 15,
        marginTop: 18,
        fontWeight: 500,
        '& .resend': {
            color: theme.palette.primary.main,
            paddingLeft: 6,
            cursor: 'pointer'
        }
    }
})
class GetMobileNumberAndOTP extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mNo: null
        }
    }

    componentDidMount() { }

    setValue = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    GetOTP = async () => {
        const { mNo } = this.state;
        if (mNo.length < 10) {
            alert("please enter valid mobile No")
            return false
        }
        let url =  Config.api_url + "notification/otp/send",
            method = "post", body = { "mobile": mNo };
        await NetworkCall(url, method, body);
        this.props.successFun(mNo)
    }
    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        const { classes } = this.props
        const { mNo } = this.state
        return (
            <div className={classes.root}>
                <Grid container justify={'center'}>
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        md={4}
                        lg={3}
                        className={classes.mainContent}
                    >
                        <Header showBackIcon />
                        <Grid className={classes.body}>
                            <Card className={classes.cardDescription} elevation={4}>
                                <Typography
                                    variant='h6'
                                    align='center'
                                    color='textPrimary'
                                    className={classes.enterotp}
                                >
                                    Enter Your Mobile Number
                                </Typography>
                                <TextField
                                    type="number"
                                    value={mNo}
                                    fullWidth={true}
                                    onChange={(e) => this.setValue("mNo", e.target.value)}
                                />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className={classes.confirambtn}
                                    onClick={() => this.GetOTP()}
                                >
                                    Get OTP
                                </Button>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(GetMobileNumberAndOTP))
