import React from 'react'
import { Grid, withStyles, Typography, Card, Avatar } from '@material-ui/core'
// import { localStorageKeys } from '../../utils';
import { withRouter } from 'react-router-dom'
import { Routes } from '../../router/routes'
import { Header } from '../../components'
//components
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'

const requestList = [
  {
    name: 'Do you need help?',
    subText: 'Post and share request',
    id: 1,
    styleClass: 'primarycard'
  },
  {
    name: 'Want to see your requests?',
    subText: 'Provide mobile number and view',
    id: 2,
    styleClass: 'secondycard'
  }
]

const adList = [
  {
    name: 'Blood donation is a vital part.',
    description:
      'It allows for blood transfusion as a life-sustaining and life-saving procedure.'
  },
  {
    name: 'Blood donation is a vital part.',
    description:
      'It allows for blood transfusion as a life-sustaining and life-saving procedure.'
  }
]
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 20,
    width: 400,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  cardStyle: {
    minHeight: 100,
    marginBottom: theme.spacing(2),
    backgroundColor: ''
  },
  description: {
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  imgStyle: {
    height: 300,
    width: '100%',
    objectFit: 'cover'
  },
  body: {
    padding: 20,
    height: 'calc(100vh - 64px)',
    overflow: 'auto'
  },
  cardDescription: {
    minHeight: 100,
    marginBottom: theme.spacing(2),
    // backgroundColor: theme.palette.secondary.main,
    padding: 20,
    color: '#fff',
    borderRadius: 20
  },
  listOfDes: {
    marginBottom: theme.spacing(2),
    padding: 16,
    borderRadius: 20,
    cursor: 'pointer',
    border: '1.4px solid #fff',
    transition: '0.4s ease'
  },
  listOfmenus: {
    marginBottom: theme.spacing(2),
    padding: 16,
    borderRadius: 20,
    cursor: 'pointer',
    minHeight: 100,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#fff',
    transition: '0.4s ease',
    '&:hover': {
      // background: "#fad9f1",
      // border: "1.4px solid #f9cded",
      transform: 'scale(1.03)'
    }
  },
  iconList: {
    border: '2px solid #f2f2f2',
    padding: '10px 10px',
    borderRadius: 50,
    marginRight: 16,
    display: 'flex'
  },
  cardDescriptionImg: {
    minHeight: 100,
    padding: 20,
    color: '#fff',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    boxShadow: 'none !important',
    marginBottom: '80px'
  },
  mainTitle: {
    padding: '0px 20px 20px 20px'
  },
  cardTitle: {
    display: 'flex',
    justify: 'start',
    alignItems: 'center'
  },
  menuDescription: {
    fontSize: 12,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  title: {
    fontSize: 20,
    fontFamily: 'humanchain_bold !important'
  },
  backimage1: {
    backgroundImage: `url(${'"/images/Mask Group 5.png"'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  backimage2: {
    backgroundImage: `url(${'"/images/Mask Group 6.png"'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }
})
class Home extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    console.log('home')
  }

  moveToFlow = data => {
    if (data.id === 1) {
      this.props.history.push(Routes.registrationType)
    } else {
      if (localStorage.userId) {
        this.props.history.push(Routes.requests)
      } else {
        this.props.history.push(Routes.mlogin)
      }
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            {/* header */}
            <Header subTitle='Making an impact by bridging people' />
            <Grid className={classes.body}>
              <Grid item className={classes.mainTitle}>
                <Typography
                  variant={'h5'}
                  style={{ fontSize: 26, fontWeight: 600 }}
                >
                  <img
                    src='/images/Group 43.svg'
                    style={{
                      marginRight: -8
                    }}
                  />
                  Making an impact by bridging people
                </Typography>
              </Grid>
              {/* Description card */}
              {adList.map((val, i) => {
                return (
                  <Card
                    className={`${classes.listOfDes}`}
                    elevation={4}
                    key={i}
                  >
                    <Grid item className={classes.cardTitle}>
                      {/* <FavoriteBorderRoundedIcon color={"primary"} /> */}
                      <img src='/images/handshake.svg' />
                      &nbsp;
                      <Typography
                        variant={'body1'}
                        color={'primary'}
                        style={{
                          fontSize: 18,
                          marginLeft: 10,
                          fontWeight: 600
                        }}
                      >
                        {val.name}
                      </Typography>
                    </Grid>
                    <div>
                      <Typography
                        variant={'subtitle2'}
                        color={'textSecondary'}
                        style={{ fontSize: '12px' }}
                      >
                        {val.description}
                      </Typography>
                    </div>
                  </Card>
                )
              })}

              {/* Type of request list */}
              {requestList.map((val, i) => {
                return (
                  <Card
                    className={
                      classes.listOfmenus +
                      ' ' +
                      val.styleClass +
                      ' ' +
                      `${i === 0 ? classes.backimage1 : classes.backimage2}`
                    }
                    elevation={4}
                    key={i}
                    onClick={() => this.moveToFlow(val)}
                  >
                    {/* <img src='/images/Mask Group 5.png' /> */}
                    <div>
                      <Grid item className={classes.cardTitle}>
                        <Typography variant={'body1'} className={classes.title}>
                          {val.name}
                        </Typography>
                      </Grid>
                      <div>
                        <Typography
                          variant={'subtitle2'}
                          className={classes.menuDescription}
                        >
                          {val.subText}
                          <ArrowForwardIosRoundedIcon
                            style={{ fontSize: 12, marginLeft: 5 }}
                          />
                        </Typography>
                      </div>
                    </div>
                  </Card>
                )
              })}

              {/* Image ads */}
              <Card className={classes.cardDescriptionImg} elevation={4}>
                <Avatar
                  style={{ width: '100%', height: '100%' }}
                  src={'images/Rectangle 8@2x.png'}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(Home))
