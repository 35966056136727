import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import { Routes } from "./routes";

import {
  NotFound,
  Home,
  OtpVerify,
  Requests,
  RegistrationType,
  Willinghelp,
  CommentsBox,
  Needs,
  RegistrationForm,
  SharePost,
  Mobilelogin,
  VerifyRequestDetails,
  ShareRequestDetails,
  Notification,
  OtpVerifyResponse
} from './../screens';

const RouterApp = (props) => {

  return (
    <Router>
      <Switch>
        {/* form component list */}
        <Route exact path={Routes.home} component={Home} />
        <Route exact path={Routes.mlogin} component={Mobilelogin} />
        <Route exact path={Routes.otpVerify} component={OtpVerify} />
        <Route exact path={Routes.requests} component={Requests} />
        <Route exact path={Routes.willinghelp} component={Willinghelp} />
        <Route exact path={Routes.comments} component={CommentsBox} />
        <Route exact path={Routes.registrationType} component={RegistrationType} />
        {/* <Route exact path={Routes.home} component={NotFound} /> */}
        <Route exact path={Routes.needs} component={Needs} />
        <Route exact path={Routes.registrationForm} component={RegistrationForm} />
        <Route exact path={Routes.sharePost} component={SharePost} />
        <Route exact path={Routes.requestVerification} component={VerifyRequestDetails} />
        <Route exact path={Routes.notification} component={Notification} />
        <Route exact path={Routes.OtpVerifyResponse} component={OtpVerifyResponse} />
        <Route exact path={Routes.sharedRequest} component={ShareRequestDetails} />
        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
