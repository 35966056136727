import { makeStyles, Grid, Paper } from '@material-ui/core'
import React from 'react'
import { NotFoundSection } from '../../components'
import { FormTreeView } from './formTree'
import { FormBuilder } from './formBuilder'
import { DrawerContext } from '../../contexts';
import { DrawerProps } from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // padding: 16,
    [theme.breakpoints.only('xs')]: {
      // padding: 10
    }
  },
  backBtn: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 12
    },
    boxShadow: '0px 15px 25px #110f4712',
    backgroundColor: '#fff',
    paddingRight: theme.spacing(2),
    '&:hover': {
      boxShadow: '0px 15px 25px #110f4712',
      backgroundColor: '#fff',
      paddingRight: theme.spacing(2)
    }
  },
  treePaper: {
    padding: theme.spacing(1),
    marginBottom: 10,
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      // height: 'calc(100vh - 150px)',
      marginBottom: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0
    },
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    borderTopRightRadius: 12,
    // border: '1px solid #0000001A',
    // boxShadow: '0px 15px 25px #110f4712',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  formBuilderPaper: {
    [theme.breakpoints.up('sm')]: {
      // height: 'calc(100vh - 150px)',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    },
    backgroundColor: "transparent",
    overflow: 'auto',
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    borderTopRightRadius: 12,
    // boxShadow: '0px 15px 25px #110f4712',
    // border: '1px solid #0000001A'
  },
  subbacktext: {
    opacity: 0.6,
    fontSize: 12,
    paddingLeft: 4
  },
}))

export const FormRenderer = ({
  tree_view,
  forms,
  parent_form_id,
  entry_id,
  isEdit,
  answer,
  onSubmitBtnClicked,
  submitBtnName
}) => {

  const classes = useStyles()

  const [state, setState] = React.useState({
    selected_form: forms[0],
    form_id: forms[0]?.id,
    answer: answer,
  })

  const drawerContext = React.useContext(DrawerContext)

  const getSectionId = data => {
    setState({
      ...state,
      selected_form: forms?.filter(_ => _.id === data.id)?.[0],
      form_id: data.id
    })

    drawerContext.setDrawer({
      ...drawerContext,
      open: false,
      onClose: () => onViewSectionDrawerClosed()
    })

  }

  const viewSectionDrawer = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.left,
      variant: DrawerProps.variant.temporary,
      component: <div style={{ padding: 20 }}>
        <FormTreeView
          tree_view={tree_view}
          getSectionId={getSectionId}
          selected_section_id={state.form_id}
          selected_form={state.selected_form}
          isEdit={isEdit}
          view={view}
          historyOfViewedForms={historyOfViewedForms}
          setView={setView}
        />
      </div>,
      onClose: () => onViewSectionDrawerClosed()
    })
  }

  const onViewSectionDrawerClosed = () => {

  }

  const updateAnswer = (answer) => {

    setState({
      ...state,
      answer
    })
  }

  const [view, setView] = React.useState([]);

  const [historyOfViewedForms, setHistoryOfViewedForms] = React.useState([]);

  React.useEffect(() => {
    setHistoryOfViewedForms([...historyOfViewedForms, view]);
    //  console.log(historyOfViewedForms,"history")
    // 
    // eslint-disable-next-line 
  }, [view])

  const onSubmit = () => {
    onSubmitBtnClicked(state);
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction='column'
        justify='flex-start'
        alignItems='stretch'
      >
        {/* Main Content */}
        {forms.length > 0 && (
          <Grid
            item
            container
            direction='row'
            justify='flex-start'
            alignItems='flex-start'
          >

            {/* Form View */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper elevation={0} className={classes.formBuilderPaper}>
                <FormBuilder
                  getSectionId={getSectionId}
                  form_id={state.form_id}
                  forms={forms}
                  parent_form_id={parent_form_id}
                  isEdit={isEdit}
                  entry_id={entry_id}
                  answer={answer}
                  onAnswerUpdated={updateAnswer}
                  view={view}
                  setView={setView}
                  viewSection={viewSectionDrawer}
                  onSubmitBtnClicked={onSubmit}
                  submitBtnName={submitBtnName}
                />
              </Paper>
            </Grid>


          </Grid>
        )}

        {forms.length === 0 && (
          <NotFoundSection message='No Forms Added Yet!' />
        )}
      </Grid>
    </div>
  )
}
