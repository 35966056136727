/**
 * @author Hari Ram
 * @email hariram@qdmplatforms.com
 * @create date 2021-01-28
 * @modify date 2021-02-15
 * @desc Input component
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {},
}));



const Input = (props) => {
  const classes = useStyles();
  return (
    <TextField
      type={props.type}
      className={classes.root}
      id={props.id}
      label={props.label}
      required={props.isrequired}
      variant={"outlined"}
      fullWidth
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{ readOnly:props.isReadonly ?? false }}
      size="small"
      multiline={props.multiline}
      rows={5}
      rowsMax={10}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value}
    />
  );
};
Input.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  //   variant: PropTypes.oneOf(["Standard" | "filled" | "outlined"]),
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  isReadonly: PropTypes.bool
};
Input.defaultProps = {
  label: "label",
  //   variant: "outlined",
  multiline: false,
  type: "text",
};
export default Input;
