import React from 'react'
import {
  makeStyles,
  Avatar,
  Typography,
  Button,
  Snackbar
} from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'
import copy from 'copy-to-clipboard'
// import ShareButton from "react-web-share-button";
import { RWebShare } from 'react-web-share'

const useStyles = makeStyles(theme => ({
  postStyle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  },
  large: {
    width: 160,
    height: 160,
    // border: '1px solid lightgrey',
    margin: 'auto'
  },
  sharePostStyle: {
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  },
  buttonActions: {
    '& .share-btn': {
      color: '#fff',
      backgroundColor: '#ff00a8',
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      padding: '6px 16px',
      fontSize: '0.875rem',
      minWidth: '64px',
      boxSizing: 'border-box',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontWeight: '500',
      lineHeight: '1.75',
      borderRadius: 4,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
      border: 0,
      fontFamily: 'inherit'
    },
    [theme.breakpoints.down('xs')]: {
      '& .lapView': {
        display: 'none'
      }
    },
    [theme.breakpoints.up('sm')]: {
      '& .share-btn': {
        display: 'none'
      },
      '& .mobView': {
        display: 'none'
      }
    },
    textAlign: 'center'
  },
  btns: {
    width: '200px',
    height: '50px'
  }
}))

export const SharePostHook = ({
  imgUrl = '',
  title = '',
  subTitle = '',
  description = '',
  shareButtonText = '',
  shareButtonAction = null,
  copyButtonText = '',
  copyButtonAction = null,
  callback = () => null,
  url = '',
  shareTitle = ''
}) => {
  const classes = useStyles()

  const [state, setState] = React.useState({
    open: false
  })

  const copyLink = () => {
    // navigator.clipboard.writeText(document.location.href)
    copy(url)

    setState({
      ...state,
      open: true
    })

    copyButtonAction && copyButtonAction()
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setState({
      ...state,
      open: false
    })
  }

  return (
    <React.Fragment>
      {imgUrl && (
        <Avatar alt='Remy Sharp' src={imgUrl} className={classes.large} />
      )}
      <div className={classes.postStyle}>
        <Typography variant='h6' style={{ fontWeight: 600, marginBottom: 2 }}>
          {title}
        </Typography>
        <Typography variant='body1'>{subTitle}</Typography>
      </div>
      <Typography className={classes.sharePostStyle} variant='body1'>
        {description}
      </Typography>
      <div className={classes.buttonActions}>
        {/* <Button className={'mobView'} variant="contained" color="primary" onClick={()=>handleOnClick()}>Share Now</Button> */}
        {/* {
              shareButtonText.length > 0 &&  <ShareButton buttonText={shareButtonText} title="Share Post" text="Check out our latest request" url={document.location.href} />
          } */}
        {shareButtonText.length > 0 && (
          <div className={'mobView'}>
            <RWebShare
              data={{
                text: shareTitle,
                url: url,
                title: 'Share Post'
              }}
              onClick={shareButtonAction && shareButtonAction}
            >
              <Button
                className={classes.btns}
                variant='contained'
                color='primary'
              >
                {shareButtonText}
              </Button>
            </RWebShare>
          </div>
        )}
        {copyButtonText.length > 0 && (
          <Button
            className={'lapView' + ' ' + classes.btns}
            variant='contained'
            color='primary'
            onClick={copyLink}
          >
            {copyButtonText}
          </Button>
        )}
        {copyButtonText.length === 0 && (
          <Button
            className={'lapView' + ' ' + classes.btns}
            variant='contained'
            color='primary'
            onClick={callback}
          >
            Done
          </Button>
        )}

        <Snackbar
          open={state.open}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert severity='success' variant='filled'>
            Link Copied
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  )
}
