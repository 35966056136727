import React from 'react'
import { withRouter } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import { Typography, Grid, withStyles, Button, Card } from '@material-ui/core'
import { Header } from '../../components'
import { Routes } from '../../router/routes';
import { NetworkCall } from "../../networkcall";
import { withAllContexts } from '../../HOCs'
import Config from '../../config'
import { AlertProps } from '../../utils'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 14,
    width: 400,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  otp: {
    margin: 'auto',
    '& input': {
      height: '4.6em',
      width: '4.6em !important',
      background: '#EEEEEEA6',
      borderRadius: 10,
      border: 'none',
      '&:focus-visible': {
        border: '2px solid #ff00a8',
        outline: 'none'
      }
    }
  },
  subtitle: {
    fontSize: 18,
    textAlign: 'left',
    fontWeight: 600
  },
  confirambtn: {
    width: '100%',
    padding: '14px',
    margin: '10px 0px',
    marginTop: 16,
    marginBottom: 30,
    '&:hover': {
      background: theme.palette.primary.main
    }
  },
  change: {
    cursor: 'pointer',
    fontSize: 15,
    textTransform: 'capitalize',
    borderRadius: 8,
    padding: '4px 22px'
  },
  enterotp: {
    fontSize: 16,
    marginBottom: 20
  },
  body: {
    padding: 20,
    height: 'calc(100vh - 64px)',
    overflow: 'auto'
  },
  otptitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cardDescription: {
    minHeight: 100,
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.contrastText,
    padding: 20,
    color: '#fff',
    borderRadius: 20,
    fontSize: 20,
    paddingTop: 24
  },
  recived: {
    justifyContent: 'center',
    display: 'flex',
    fontSize: 15,
    marginTop: 18,
    fontWeight: 500,
    '& .resend': {
      color: theme.palette.primary.main,
      paddingLeft: 6,
      cursor: 'pointer'
    }
  }
})
class OtpVerifyComonent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: ""
    }
  }

  componentDidMount() { }

  verifyOtp = async () => {

    this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: true, message: "Verifying OTP..." })

    const { mobileNo, redirectPath, successFun, userName } = this.props
    const { value } = this.state

    let url = Config.api_url + "notification/otp/verify",
      method = "post", body = {
        "mobile": mobileNo,
        "otp": value,
        "mobile_con": "91",
        "name" : userName
      };

    NetworkCall(url, method, body).then((res) => {

      localStorage.setItem("userId", res.data.profile_id)
      localStorage.setItem("token", res.data.auth_token)

      if (successFun) {
        successFun()
        this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: false, })
      } else {
        this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: false, })
        this.props.history.push(redirectPath ? redirectPath : Routes.sharePost)
      }

    }).catch(error => {
      this.props.backDrop.setBackDrop({ ...this.props.backDrop, open: false, })
      this.props.alert.setSnack({
        ...this.props.alert.alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something went wrong, Unable to Verify OTP.",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center
      })
    })
  }
  goBack = () => {
    const { changeMobileNo } = this.props;
    if (changeMobileNo) {
      changeMobileNo()
    } else {
      this.props.history.goBack();
    }
  }
  handleChange = otp => this.setState({ value: otp })
  render() {
    const { classes, mobileNo } = this.props
    const { value } = this.state
    return (
      <div className={classes.root}>
        <Grid container justify={'center'}>
          <Grid
            item className={classes.mainContent}
          >
            <Header showBackIcon />
            <Grid className={classes.body}>
              <Card className={classes.cardDescription} elevation={4}>
                <div className={classes.otptitle}>
                  <Typography
                    variant='h5'
                    align='center'
                    color='textPrimary'
                    className={classes.subtitle}
                  >
                    We've sent the OTP to {mobileNo ? mobileNo : "xxxxxxxxxxx"}.
                  </Typography>
                  <Button
                    className={classes.change}
                    variant='outlined'
                    color='primary'
                    onClick={() => this.goBack()}
                  >
                    Change
                  </Button>
                </div>
                <br />
                <Typography
                  variant='h6'
                  align='center'
                  color='textPrimary'
                  className={classes.enterotp}
                >
                  Enter OTP
                </Typography>
                <OtpInput
                  isInputNum
                  shouldAutoFocus
                  className={classes.otp}
                  value={value}
                  onChange={this.handleChange}
                  numInputs={4}
                  separator={<span>&nbsp;</span>}
                />
                <Typography
                  variant='caption'
                  align='center'
                  color='textPrimary'
                  className={classes.recived}
                >
                  Haven't received? <span className='resend'>Resend</span>
                </Typography>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.confirambtn}
                  onClick={() => this.verifyOtp()}
                >
                  Confirm
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withAllContexts(withRouter(withStyles(styles)(OtpVerifyComonent)))
