import React from 'react'
import { Helmet } from 'react-helmet'
import {
  Grid,
  Typography,
  IconButton,
  makeStyles,
  Avatar,
  AppBar,
  Badge,
  Toolbar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import HomeIcon from '@material-ui/icons/Home'
import PropTypes from 'prop-types'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Routes } from '../../router/routes'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    height: '64px',
    position: 'relative'
  },
  iconStyle: {
    color: theme.palette.secondary.main,
    fontSize: 25
  },
  item: {
    display: 'flex',
    alignItems: 'center'
  },
  headerName: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.4
  },
  headerNameSubText: {
    fontSize: '10px',
    lineHeight: 1.8
  },
  mainHeader: {
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px'
  },
  leftPosition: {
    position: 'absolute',
    left: 20,
    color: '#000',
    fontSize: 20,
    cursor: 'pointer'
  },
  avatarStyle: {
    width: 34,
    height: 34,
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    backgroundColor: '#D1CEF4'
  }
}))
export const Header = ({
  title = 'Human Circle',
  subTitle = '',
  showCloseIcon = false,
  showHomeIcon = false,
  showNotificationIcon = false,
  showAvatar = false,
  showBackIcon = false,
  avatarText = 'A',
  avatarAlt = '',
  avatarSrc = '',
  isNoHelmat,
  props
}) => {
  const classes = useStyles()
  let history = useHistory()
  const checkJustifyContent = () => {
    if (showCloseIcon || showHomeIcon || showNotificationIcon || showAvatar) {
      return 'space-between'
    } else {
      return 'center'
    }
  }

  const routerTo = path => {
    history.push(path)
  }

  const goBack = () => {
    history.goBack()
  }

  const goHome = () => {
    history.push('/')
  }

  return (
    <div className={classes.root}>
      {!isNoHelmat && (
        <Helmet>
          <html lang='en' />
          <title>Human Chain</title>
          <meta name='description' content={'Human Chain'} />
          <link rel='canonical' href={window.location.href} />
        </Helmet>
      )}
      <AppBar
        position={'absolute'}
        color={'inherit'}
        className={classes.mainHeader}
      >
        <Toolbar
          variant={'regular'}
          style={{
            paddingRight: 0
          }}
        >
          <Grid
            container
            direction='row'
            alignItems='center'
            justify={checkJustifyContent()}
          >
            {showBackIcon && (
              <ArrowBackIosIcon
                onClick={() => goBack()}
                className={classes.leftPosition}
              />
            )}
            <Grid
              item
              alignItems={'center'}
              style={{
                display: 'flex',
                marginLeft:
                  showBackIcon &&
                  checkJustifyContent() === 'space-between' &&
                  30
              }}
            >
              {/* <div onClick={goHome} style={{cursor:"pointer"}}> */}
              {/* <FavoriteBorderRoundedIcon color={"primary"} fontSize={"large"} style={{ marginRight: 12 }} /> */}
              {/* </div> */}
              <div>
                <img src='/images/Group 7.png' onClick={goHome} />
                {/* {title && <Typography variant={"subtitle1"} className={classes.headerName}>{title}</Typography>}
                {subTitle && <Typography variant={"subtitle2"} color={"textSecondary"} className={classes.headerNameSubText}>{subTitle}</Typography>} */}
              </div>
            </Grid>
            <Grid item className={classes.item}>
              {showCloseIcon && (
                <IconButton>
                  <CloseIcon
                    onClick={() =>
                      localStorage.getItem('userId')
                        ? history.push('/requests')
                        : history.push('/')
                    }
                    className={classes.iconStyle}
                  />
                </IconButton>
              )}
              {showHomeIcon && (
                <IconButton onClick={() => routerTo(Routes.requests)}>
                  <HomeIcon className={classes.iconStyle} />
                </IconButton>
              )}
              {showNotificationIcon && (
                <IconButton>
                  <Badge
                    variant='dot'
                    color='primary'
                    onClick={() => routerTo(Routes.notification)}
                  >
                    <NotificationsNoneIcon className={classes.iconStyle} />
                  </Badge>
                </IconButton>
              )}
              {showAvatar && (
                <Avatar
                  className={classes.avatarStyle}
                  alt={avatarAlt}
                  src={avatarSrc}
                  style={{
                    marginRight: 6
                  }}
                >
                  {avatarText}
                </Avatar>
              )}
            </Grid>
            {/* <Divider /> */}
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}
Header.propTypes = {
  title: PropTypes.string
}
