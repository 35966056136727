import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles, Grid, Typography } from '@material-ui/core'
import Willinghelplistcard from '../../components/helpcard/index'
import { Header } from '../../components'
import { getAllRequestResponse } from "../../graphql/queries";
import { withApollo } from "react-apollo";
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  mainContent: {
    height: '100vh',
    borderRadius: 20,
    background: '#fdeef7'
  },
  back: {
    marginBottom: -6,
    cursor: 'pointer'
  },
  body: {
    padding: '10px 20px',
    height: 'calc(100vh - 64px)',
    overflow: 'auto'
  },
  titlecomments: {
    fontSize: 16,
    fontWeight: 600,
    paddingLeft: 20,
    paddingTop: 10,
    '& span': {
      width: 16,
      height: 16,
      background: theme.palette.secondary.main,
      color: '#fff',
      borderRadius: '50%',
      position: 'absolute',
      fontSize: 12,
      marginTop: 2,
      marginLeft: 4,
      paddingLeft: 4
    }
  }
})
class Willinghelp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: []
    }
  }

  componentDidMount() {
    const { requestId } = this.props?.location?.state ?? {};
    this.props.client.query({
      query: getAllRequestResponse,
      variables: { requestId },
      fetchPolicy: 'no-cache'
    }).then(res => {
      let listData = []
      res.data.allRequestResponses.nodes.map(val => {
        let list = JSON.parse(val.response)
        let data = {}
        Object.values(list).map(val => {
          data[val.tag] = val.answer;
          return false
        })
        listData.push({
          title: data["Name"],
          dis: data["Mobile Number"],
          img: data["Name"]?.[0]
        })
        return false
      })
      this.setState({ list: listData })
    })
  }



  render() {
    const { classes } = this.props
    const { list } = this.state
    return (
      <div className={classes.root}>
        <Grid container justify={'center'}>
          <Grid
            item
            xs={12}
            sm={5}
            md={4}
            lg={3}
            className={classes.mainContent}
          >
            <Header showBackIcon />
            <Typography variant='h5' className={classes.titlecomments}>
              Willing to help<span>{list?.length ?? 0}</span>
            </Typography>
            <Grid className={classes.body}>
              <Willinghelplistcard data={list} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(withApollo(Willinghelp)))
