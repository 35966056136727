import React from "react";
import { GetMobileNumberAndOTP, OtpVerifyComonent } from "../../components";
import { Routes } from "../../router/routes";

class Mobilelogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            issendotp: false,
            mNo: null
        }
    }
    successFun = (mno) => {
        this.setState({
            issendotp: true,
            mNo: mno
        })
    }
    changeMobileNo = () => {
        this.setState({
            issendotp: false
        })
    }
    handleOptSuccess = (userId) => {
        this.props.history.push({
            pathname: Routes.requests,
            state: { userId }
        })
    }
    render() {
        let { issendotp, mNo } = this.state;
        return (
            <React.Fragment>
                {issendotp ? <OtpVerifyComonent redirectPath={Routes.requests} mobileNo={mNo} /> : <GetMobileNumberAndOTP successFun={this.successFun} changeMobileNo={this.changeMobileNo} />}
            </React.Fragment>
        )
    }
}

export default Mobilelogin;