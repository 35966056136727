import React from 'react'
import { withStyles, Grid, Card } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { withAllContexts } from '../../HOCs'
import { SharePostHook, Header } from '../../components'
import { Routes } from '../../router/routes'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  body: {
    padding: 20,
    height: 'calc(100vh - 64px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mainContent: {
    height: '100vh',
    background: '#fdeef7',
    overflow: 'auto',
    borderRadius: 20,
    width: 400,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%'
    }
  },
  listOfDes: {
    marginBottom: theme.spacing(2),
    padding: '30px 16px',
    borderRadius: 20,
    cursor: 'pointer',
    border: '1.4px solid #fff',
    transition: '0.4s ease',   
     width: '100%'
  }
})

class SharePost extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      copyLink: this.props?.location?.state?.copyLink,
      title: this.props?.location?.state?.page_title??''
    };
  }

  componentDidMount() {

  }

  componentDidMount () {}

  render () {
    const { classes, location } = this.props
    const { rTypeId } = location?.state ?? {}

    let getDataId = rTypeId ? rTypeId : "default";
    let datalist = textData[getDataId];
debugger
    return (
      <div className={classes.root}>
        <Grid container justify={'center'}>
          <Grid item className={classes.mainContent}>
            <Header showCloseIcon={false} showHomeIcon />
            <Grid className={classes.body}>
              <SharePostHook
                imgUrl='/images/Group 80.svg'
                title={datalist.title}
                subTitle={datalist.subText}
                description={datalist.des}
                shareButtonText="Share Now"
                copyButtonText={datalist.copyText}
                callback={() => this.props.history.push(Routes.home)}
                url={this.state.copyLink}
                shareTitle={this.state.title}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(withAllContexts(SharePost)))

const textData = {
  1: {
    title: "You're making a difference",
    subText: "We've marked the request as verified",
    copyText: ''
  },
  2: {
    title: "You've a big heart",
    subText: 'your contact details has been shared to them',
    copyText: ''
  },
  3: {
    title: 'Thanks for your effort',
    subText: "We're informing to them to close",
    copyText: ''
  },
  default: {
    title: "Let's have the faith.",
    subText: 'Request is ready for sharing!',
    des: 'Please share for maximum reach.',
    copyText: 'Copy Link'
  }
}
