import React from 'react'
import { Typography, makeStyles, Button, Card, Avatar } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import CallIcon from '@material-ui/icons/Call'
import { Routes } from '../../router/routes'
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { getAllResponseType } from "../../graphql/queries";
import { withApollo } from "react-apollo";
import { RWebShare } from "react-web-share"; 
import { GiveMeShareURL } from "../../utils";
import { RequestData } from "../../funcations";
const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,.01)',
    margin: '28px 0px',
    borderRadius: 20
  },
  phoneCall: {
    textDecoration: 'none'
  },
  emergencymain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .emergency': {
      padding: '4px 11px',
      background: '#FF0000',
      color: theme.palette.primary.contrastText,
      borderRadius: 8,
      textTransform: 'capitalize',
      boxShadow: 'none'
    },
    '& .date': {
      color: theme.palette.text.secondary,
      fontSize: 15
    },
    '& .closeddate': {
      //   borderLeft: '1.1px solid #eaeaea',
      //   paddingLeft: 16
    },
    '& .closed': {
      padding: '4px 14px',
      background: '#FF880052',
      color: '#FF8800',
      borderRadius: 8,
      textTransform: 'capitalize',
      boxShadow: 'none'
    }
  },
  username: {
    padding: '14px 0px',
    fontSize: 17,
    fontWeight: 600
  },
  lists: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 14,
    padding: '6px 0px',
    '& .heart': {
      color: theme.palette.primary.main,
      marginRight: 10
    }
  },
  social: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1.1px solid #bdbdbd52',
    padding: '0px 20px',
    paddingTop: 6,
    '& .MuiAvatar-colorDefault': {
      margin: 'auto',
      marginBottom: 4,
      width: 40,
      height: 40,
      background: theme.palette.secondary.main
    },
    '& .names': {
      cursor: 'pointer',
      fontSize: 14,
      marginBottom: 6
    },
    '& .arrows': {
      fontSize: 14,
      marginBottom: -2
    }
  },
  closeshare: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .heart': {
      color: theme.palette.primary.main,
      marginRight: 10
    },
    '& .names': {
      border: '1.1px solid #bdbdbd52',
      width: '100%',
      padding: 10,
      textAlign: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      fontSize: 16,
      borderLeft: 'none',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '-4px'
    },
    '& .namesleft': {
      borderRight: 'none'
    },
    height:"50px"
  },
  pd: {
    padding: 20,
    '& .expired': {
      padding: '4px 14px',
      background: '#FF00002E',
      color: '#FF0000',
      borderRadius: 8,
      textTransform: 'capitalize',
      boxShadow: 'none'
    },
    '& .dis': {
      fontSize: 16,
      fontWeight: 600,
      marginTop: 6
    },
    '& .reopen': {
      marginBottom: -2,
      fontSize: 16
    }
  },
  call: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .calbtn': {
      borderRadius: 8,
      '& .callicon': {
        fontSize: 16,
        marginRight: 6
      },
      textTransform: 'capitalize'
    }
  },
  closed: {
    background: '#EEEEEE52',
    boxShadow: 'none',
    padding: 10,
    margin: '10px 0px',
    border: '1px solid #eaeaea',
    '& .title': {
      fontSize: 15,
      fontWeight: 600,
      color: '#9B9B9B'
    },
    '& .dis': {
      fontSize: 15
    },
    '& .reopen': {
      fontSize: 16
    }
  },
  btn: {
    width: '100%',
    justifyContent: 'space-between',
    height: 50,
    marginBottom: '3%',
    fontSize: 18,
    textTransform: 'none'
  },
  iconsimage:{
   "& img":{
    margin:"auto"
   }
  }
}))

function Feedcard(props) {
  const { list, dontredirect } = props
  const classes = useStyles();
  const [state, setState] = React.useState({ rtype: [] })
  React.useEffect(() => {
    props.client
      .query({
        query: getAllResponseType,
        variables: {},
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        setState({ ...state, rtype: res.data.allMasterResponseTypes.nodes })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setShareUrl = (data) => {
    let url = window.location.origin +"/"+ GiveMeShareURL(RequestData(data?.request), data?.masterRequestTypeByRequestType?.requestType, data?.id);
    return url;
  }
  return (
    <>
      <Card
        className={classes.card}
        elevation={4}
      >
        <div className={classes.pd}>
          <div className={classes.emergencymain}>
            <Typography variant='caption' className='date'>
              {props.list?.date}
            </Typography>
            {list.isemergency && (
              <Button className='emergency' variant='contained'>
                Emergency
              </Button>
            )}
          </div>
          <Typography variant='h6' className={classes.username}>
            {props.list?.title}
          </Typography>
          {(!dontredirect || ( dontredirect && props.list?.status === 1 )) && <div className={classes.call}>
            <span>
              {props.list?.nameList?.map(_n => {
                return (
                  <Typography variant='caption' className={classes.lists}>
                    <div style={{width:"20px",height:"20px",objectFit:"none", display: 'flex'}}
                    className={classes.iconsimage}><img src={_n?.img}/></div> {_n?.name}
                  </Typography>
                )
              })}
            </span>
            <a href={"tel:+91" + props.list?.nameList?.[2]} className={classes.phoneCall}><Button className="calbtn" variant="outlined" color="primary">
              <CallIcon className="callicon" /> Call
            </Button></a>
          </div>}
        </div>

        <div className={classes.social}>
          <Typography variant='caption' className='names'>
            <Avatar>{list.verifiedCount?.totalCount ? list.verifiedCount.totalCount : 0}</Avatar>
                        Verified
          </Typography>
          <Typography
            variant='caption'
            className='names'
            onClick={() => {
              if (!dontredirect) {
                props.history.push({
                  pathname: Routes.comments,
                  state: { requestId: list?.id }
                })
                localStorage.setItem("reqId", list?.id)
              }
            }}
          >
            <Avatar>{list?.commentsByRequestId?.totalCount ? list?.commentsByRequestId?.totalCount : 0}</Avatar>
            Comments {!dontredirect && <ArrowForwardIosIcon className='arrows' />}
          </Typography>
          <Typography
            variant='caption'
            className='names'
            onClick={() => {
              if (!dontredirect) {
                props.history.push({
                  pathname: Routes.willinghelp,
                  state: { requestId: list?.id }
                })
              }
            }}
          >
            <Avatar>{list.canHelpCount?.totalCount ? list.canHelpCount.totalCount : 0}</Avatar>
                        Can Help {!dontredirect && <ArrowForwardIosIcon className='arrows' />}
          </Typography>
        </div>
        {((props.list?.status !== 2 && props.list?.status !== 3) && (!props.dontShowShare || dontredirect)) && <div className={classes.closeshare}>
          {dontredirect ? <Typography
            variant="caption"
            className="names"
            onClick={() => {
              props.history.push({
                pathname: Routes.comments,
                state: { requestId: list?.id }
              })
              localStorage.setItem("reqId", list?.id)
            }}
          >
            {/* <CloseIcon className='heart' />  */}
            Comment
          </Typography> :
            <Typography
              variant="caption"
              className="names"
              onClick={() => props.handleopemodle(list?.id)}
            >
              {/* <CloseIcon className='heart' />  */}
            Close
          </Typography>}

          <RWebShare
              data={{
                text: list.title + '\n',
                url: setShareUrl(list),
                title: "Share Post"
              }}
            >
              <Typography
                variant="caption"
                className="names namesleft"
                // onClick={() => props.handleShare(list.id)}
              >
                <img src='/images/share-option.svg'/>&nbsp;
                {/* <ShareIcon className="heart" /> */}
                 Share
              </Typography>
          </RWebShare>

        </div>}

        {props.list?.status === 2 && (
          <div className={classes.pd}>
            <div className={classes.emergencymain}>
              <Button className='closed' variant='contained' color='primary'>
                Closed
              </Button>
              <Typography variant='caption' className='closeddate'>
                {props.list?.masterRequestCloseReasonByCloseReason?.reason}
              </Typography>
            </div>
            {list?.specialNote && (
              <Card className={classes.closed} elevation={4}>
                <Typography variant='subtitle2' className='title'>
                  Special Note
                </Typography>
                <Typography className='dis'>{list?.specialNote}</Typography>
              </Card>
            )}
          </div>
        )}
        {props.list?.status === 3 && (
          <div className={classes.pd}>
            <Button className='expired' variant='contained'>
              Expired
            </Button>
            <Typography className='dis'>
              Click to reopen request.{' '}
              <ArrowForwardIosIcon className='reopen' />
            </Typography>
          </div>
        )}
        {props.dontShowShare && !props.list?.closeReason && (
          <div style={{ padding: 20 }}>
            {state.rtype.map(val => (
              <Button
                variant='contained'
                color='secondary'
                className={classes.btn}
                onClick={() =>
                  props.history.push({
                    pathname: Routes.requestVerificationParent + val.form,
                    state: {
                      request_id: list?.id,
                      response_type: val.id,
                      rTypeId: val.id
                    }
                  })
                }
                endIcon={<ChevronRightIcon />}
              >
                {val.response}
              </Button>
            ))}
          </div>
        )}
      </Card>
    </>
  )
}

export default withRouter(withApollo(Feedcard))
