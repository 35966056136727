import React from 'react';
import { FormComponents } from "../../utils";
import { TabSelect, Select, Input, DateTimePickers, CustionAddText, Notify, CheckBox, RadioButton, Location, CustomTableFormRender, CustomTableFixedRowFormRender } from "../../components";

export const ComponentToRender = ({
    index,
    data,
    value,
    isReadyOnly = false,
    onChange,
    isrequired
}) => {
    switch (data.ui_component) {
        case FormComponents.single_tab_select:
            return <TabSelect
                label={data?.question_name}
                value={[value]}
                items={data?.options?.map(_ => _) ?? []}
                onClick={(value) => onChange(index, data, value)}
                isReadyOnly={isReadyOnly}
                isrequired={isrequired}
            />;

        case FormComponents.multi_tab_select:
            return <TabSelect
                label={data?.question_name}
                value={value}
                items={data?.options?.map(_ => _) ?? []}
                onClick={(value) => onChange(index, data, value)}
                multiple={true}
                isReadyOnly={isReadyOnly}
                isrequired={isrequired}
            />

        case FormComponents.multi_select_tag:

            let allValues = value ? value.map(_ => _.value) : []

            return <Select
                label={data?.question_name}
                multiple
                value={value ? value : []}
                handleselect={(value) => onChange(index, data, value)}
                option={data?.options?.map(_ => {
                    return {
                        value: _,
                        label: _
                    }
                }).filter(_ => allValues.indexOf(_.value) === -1)}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
            />

        case FormComponents.select_modal_dropdown:
            return <Select
                label={data?.question_name}
                value={value ? [value] : []}
                handleselect={(value) => onChange(index, data, value)}
                option={data?.options?.map(_ => {
                    return {
                        value: _,
                        label: _
                    }
                })}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
            />

        case FormComponents.input_number:
            return <Input
                type={"number"}
                value={value}
                onChange={(value) => onChange(index, data, value)}
                label={data?.question_name}
                isReadonly={isReadyOnly}
                isrequired={isrequired}

            />

        case FormComponents.input_text:
            return <Input
                value={value}
                onChange={(value) => onChange(index, data, value)}
                label={data?.question_name}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
            />

        case FormComponents.input_textarea:
            return <Input
                value={value}
                onChange={(value) => onChange(index, data, value)}
                multiline
                label={data?.question_name}
                isReadonly={isReadyOnly}
                isrequired={isrequired}

            />

        case FormComponents.input_date_picker:
            return <DateTimePickers
                multiline
                value={value}
                onChange={(value) => onChange(index, data, value)}
                label={data?.question_name}
                isReadonly={isReadyOnly}
                isrequired={isrequired}

            />

        case FormComponents.custom_multi_select_tag:
            return <CustionAddText
                label={data?.question_name}
                options={value ? value : []}
                addTag={(value) => onChange(index, data, value)}
                isReadonly={isReadyOnly}
                isrequired={isrequired}

            />

        case FormComponents.label:
            return <Notify
                text={data?.question_name}
            />

        case FormComponents.note:
            return <Notify
                text={data?.question_name}
                notes
            />

        case FormComponents.popup_switch:
            return <TabSelect
                label={data?.question_name}
                value={[value]}
                items={["Yes", "No"]}
                onClick={(value) => onChange(index, data, value)}
                isrequired={isrequired}

            />;

        case FormComponents.check_box:
            return <CheckBox
                heading={data?.question_name}
                option={data?.options?.map(_ => {
                    return {
                        value: _,
                        label: _
                    }
                })}
                direction="row"
                checked={value?.length > 0 ? value : []}
                handleChange={(value) => onChange(index, data, value)}
                isReadonly={isReadyOnly}
                isrequired={isrequired}

            />
        
        case FormComponents.radio_button:
            return <RadioButton
                heading={data?.question_name}
                option={data?.options?.map(_ => {
                    return {
                        value: _,
                        label: _
                    }
                })}
                direction="row"
                value={value}
                handleChange={(value) => onChange(index, data, value)}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
            />
        
        case FormComponents.location:
            return <Location
                value={value}
                label={data?.question_name}
                handleChange={(value) => onChange(index, data, value)}
                isReadonly={isReadyOnly}
                isrequired={isrequired}

            />
        
        case FormComponents.custom_table:

            return <CustomTableFormRender
                heading={data?.question_name}
                data={data}
                handleChange={(value) => onChange(index, data, value)}
                tableData={value?.tableData?.length === 0 ? [] : value?.tableData}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
            />
        
        case FormComponents.custom_table_2:
            return <CustomTableFixedRowFormRender
                heading={data?.question_name}
                data={data}
                handleChange={(value) => onChange(index, data, value)}
                tableData={value?.tableData?.length === 0 ? [] : value?.tableData}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
            />
        default:
            return <></>;
    }
}

